import axios from 'axios';
import {API_BASE_URL, GET_AUTH_HEADER, RESET_WEB_CONFIG, WEB_CONFIG, WSSOCKET} from 'configs/AppConfig';
import { signOutSuccess } from 'store/slices/authSlice';
import store from '../store';
import {AUTH_TOKEN, USER_INFO} from 'constants/AuthConstant';
import { notification } from 'antd';
import apiService from "../services/ApiService";
import {Base64} from "js-base64";

const unauthorizedCode = [401, 403, 410]

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000
})

// Config
const TOKEN_PAYLOAD_KEY = 'authorization'

WSSOCKET.on(
	`arabianfal_database_chat:notification-${WEB_CONFIG("id")}`,
	function (data) {
		console.log("chat:new message");
		notification.info({ message: "New Message", description: data.message });
	}
);

WSSOCKET.on("arabianfal_database_settings:reload", function (data) {
	console.log("settings:reloaded");
	apiService.reloadSettings().then(
		(resp) => {
			localStorage.setItem(
				USER_INFO,
				Base64.encode(JSON.stringify(resp))
			);
			RESET_WEB_CONFIG();
		},
		(error) => {
			localStorage.removeItem(AUTH_TOKEN);
			localStorage.removeItem(USER_INFO);
			window.location.reload();
		}
	);
});

// API Request interceptor
service.interceptors.request.use(config => {

	if (GET_AUTH_HEADER()) {
		config.headers = GET_AUTH_HEADER();
	}

  	return config
}, error => {
	// Do something with request error here
	notification.error({
		message: 'Error'
	})
	Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use( (response) => {
	return response.data
}, (error) => {

	let notificationParam = {
		message: ''
	}

	// Remove token and redirect
	if (unauthorizedCode.includes(error.response.status)) {
		notificationParam.message = 'Authentication Fail'
		notificationParam.description = 'Please login again'
		localStorage.removeItem(AUTH_TOKEN)

		store.dispatch(signOutSuccess())
	}

	if (error.response.status === 101) {
		notificationParam.message = "Outdated Version";
		notificationParam.description =
			"Please refresh the page to update the app";
		notification.error(notificationParam);
		//window.location.reload();
		return Promise.reject(error);
	}

	if (error.response.status === 400 || error.response.status === 409) {
		for (let i in error.response.data) {
			if (typeof error.response.data[i] !== "string") {
				error.response.data[i].forEach((errormsg) => {
					notificationParam.message = errormsg;
					notification.error(notificationParam);
				});
			} else {
				notificationParam.message = error.response.data[i];
				notification.error(notificationParam);
			}
		}
		return Promise.reject(error);
	}

	if (error.response.status === 404) {
		notificationParam.message = 'Not Found'
	}

	if (error.response.status === 500) {
		notificationParam.message = 'Internal Server Error'
	}

	if (error.response.status === 508) {
		notificationParam.message = 'Time Out'
	}

	notification.error(notificationParam)

	return Promise.reject(error);
});

export default service
