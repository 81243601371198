import fetch from "auth/FetchInterceptor";

const apiService = {};

//===========Customers==========

apiService.searchCustomers = function (query) {
  return fetch({
    url: `customers/list?query=${query}`,
    method: "get",
  });
};

apiService.getCustomers = function (params) {
  return fetch({
    url: "customers/list",
    method: "get",
    params,
  });
};

apiService.getCustomer = function (profileid) {
  return fetch({
    url: "customers/profile/" + profileid,
    method: "get",
  });
};

apiService.deleteCustomer = function (profileid) {
  return fetch({
    url: "customers/delete/" + profileid,
    method: "get",
  });
};

apiService.createCustomer = function (params) {
  return fetch({
    url: "customers/create",
    method: "post",
    data: params,
  });
};

apiService.updateCustomer = function (params) {
  return fetch({
    url: "customers/update",
    method: "post",
    data: params,
  });
};

apiService.sendCustomerSMS = function (params) {
  return fetch({
    url: "customers/sms",
    method: "post",
    data: params,
  });
};

//===========Users==========

apiService.getUsers = function (params) {
  return fetch({
    url: "user/list",
    method: "get",
    params,
  });
};

apiService.getAllUsers = function (params) {
  return fetch({
    url: "user/listall",
    method: "get",
    params,
  });
};

apiService.exportUsers = function (params) {
  return fetch({
    url: "user/list",
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.getProfile = function () {
  return fetch({
    url: "user/profile/me",
    method: "get",
  });
};

apiService.reloadSettings = function () {
  return fetch({
    url: "login",
    method: "get",
  });
};

apiService.getNotifications = function (page, limit) {
  return fetch({
    url: `user/notifications?page=${page}&pages_info=1&limit=${limit}`,
    method: "get",
  });
};

apiService.notifsMarkAllRead = function () {
  return fetch({
    url: "user/notifications/markread",
    method: "get",
  });
};

apiService.notifsDeleteAll = function () {
  return fetch({
    url: "user/notifications/delete",
    method: "get",
  });
};

apiService.getLogs = function (params) {
  return fetch({
    url: "user/logs",
    method: "get",
    params,
  });
};

apiService.updateUser = function (params) {
  return fetch({
    url: "user/update",
    method: "post",
    data: params,
  });
};

apiService.updateMyProfile = function (params) {
  return fetch({
    url: "user/myprofile",
    method: "post",
    data: params,
  });
};

apiService.createUser = function (params) {
  return fetch({
    url: "user/create",
    method: "post",
    data: params,
  });
};

apiService.updateUserPass = function (params) {
  return fetch({
    url: "user/password",
    method: "post",
    data: params,
  });
};

apiService.updateMyPass = function (params) {
  return fetch({
    url: "user/mypassword",
    method: "post",
    data: params,
  });
};

apiService.updateUserSettings = function (params) {
  return fetch({
    url: "user/settings",
    method: "post",
    data: params,
  });
};

apiService.updateMySettings = function (params) {
  return fetch({
    url: "user/mysettings",
    method: "post",
    data: params,
  });
};

apiService.updateUserPermissions = function (params) {
  return fetch({
    url: "user/permissions",
    method: "post",
    data: params,
  });
};

apiService.getUser = function (profileid) {
  return fetch({
    url: "user/profile/" + profileid,
    method: "get",
  });
};

apiService.gpsHistory = function () {
  return fetch({
    url: "user/gps_history",
    method: "get",
  });
};

apiService.delUser = function (profileid) {
  return fetch({
    url: "user/delete/" + profileid,
    method: "get",
  });
};

apiService.sendUserSMS = function (params) {
  return fetch({
    url: "user/sms",
    method: "post",
    data: params,
  });
};

//===========Stock==========

apiService.createStock = function (params) {
  return fetch({
    url: "stock/create",
    method: "post",
    data: params,
  });
};

apiService.updateStock = function (params) {
  return fetch({
    url: "stock/update",
    method: "post",
    data: params,
  });
};

apiService.getStock = function (params) {
  return fetch({
    url: "stock/list",
    method: "get",
    params,
  });
};

apiService.getStockData = function (id) {
  return fetch({
    url: `stock/view/${id}`,
    method: "get",
  });
};

apiService.deleteStock = function (id) {
  return fetch({
    url: `stock/delete/${id}`,
    method: "get",
  });
};

//===========Vehicles==========

apiService.createVehicle = function (params) {
  return fetch({
    url: "vehicles/create",
    method: "post",
    data: params,
  });
};

apiService.updateVehicle = function (params) {
  return fetch({
    url: "vehicles/update",
    method: "post",
    data: params,
  });
};

apiService.getVehicles = function (params) {
  return fetch({
    url: "vehicles/list",
    method: "get",
    params,
  });
};

apiService.getVehicleData = function (id) {
  return fetch({
    url: `vehicles/view/${id}`,
    method: "get",
  });
};

apiService.deleteVehicle = function (id) {
  return fetch({
    url: `vehicles/delete/${id}`,
    method: "get",
  });
};

//===========Chat==========
apiService.sendMessage = function (params) {
  return fetch({
    url: "chat/new_message",
    method: "post",
    data: params,
  });
};

apiService.archiveChat = function (id) {
  return fetch({
    url: `chat/archive/${id}`,
    method: "get",
  });
};

apiService.inboxChat = function (id) {
  return fetch({
    url: `chat/inbox/${id}`,
    method: "get",
  });
};

apiService.getChats = function (page, query) {
  return fetch({
    url: `chat/list?page=${page}&query=${query}`,
    method: "get",
  });
};

apiService.getChatMessages = function (id, lstid) {
  return fetch({
    url: `chat/view/${id}?last_id=${lstid}`,
    method: "get",
  });
};

apiService.deleteChat = function (id) {
  return fetch({
    url: `chat/delete/${id}`,
    method: "get",
  });
};

//===========Settings==========

apiService.getSystemModules = function (profileid) {
  return fetch({
    url: `settings/system_modules?profileid=${profileid}`,
    method: "get",
  });
};

apiService.getSettings = function () {
  return fetch({
    url: `settings/list`,
    method: "get",
  });
};

apiService.updateSettings = function (params) {
  return fetch({
    url: "settings/update",
    method: "post",
    data: params,
  });
};

//===========Auth Control==========

apiService.getRoleTemplate = function (role_id) {
  return fetch({
    url: `auth_control/get_permissions?role_id=${role_id}`,
    method: "get",
  });
};

apiService.updateRolePerms = function (params) {
  return fetch({
    url: "auth_control/update_permissions",
    method: "post",
    data: params,
  });
};

apiService.updatePermsToAll = function (role_id) {
  return fetch({
    url: `auth_control/apply_to_all?role_id=${role_id}`,
    method: "get",
  });
};

//===========Tickets Categories==========

apiService.ticketsCategories = function () {
  return fetch({
    url: "tickets/categories/list",
    method: "get",
  });
};

apiService.ticketsCategoriesCreate = function (params) {
  return fetch({
    url: "tickets/categories/create",
    method: "post",
    data: params,
  });
};

//===========Stock Categories==========

apiService.stockCategories = function () {
  return fetch({
    url: "stock/categories/list",
    method: "get",
  });
};

apiService.stockCategoriesCreate = function (params) {
  return fetch({
    url: "stock/categories/create",
    method: "post",
    data: params,
  });
};

//===========Task Manager==========

apiService.tasksList = function (params) {
  return fetch({
    url: "tasks/list",
    method: "get",
    params,
  });
};

apiService.tasksUpdate = function (taskID, params) {
  return fetch.post(`tasks/${taskID}/update`, params);
};

apiService.tasksStatusUpdate = function (taskID, params) {
  return fetch.post(`tasks/${taskID}/status/update`, params);
};

apiService.tasksDelete = function (taskID) {
  return fetch.delete(`tasks/${taskID}/delete`);
};

apiService.tasksCreate = function (params) {
  return fetch.post(`tasks/create`, params);
};

apiService.tasksCommentSend = function (taskID, params) {
  return fetch.post(`task-comments/${taskID}/create`, params);
};

apiService.tasksAddPerson = function (params) {
  return fetch.post(`tasks/person/add`, params);
};

apiService.tasksUpdatePerson = function (params) {
  return fetch.post(`tasks/person/update`, params);
};

apiService.tasksLabels = function (params) {
  return fetch.get("labels/list", params);
};

apiService.tasksMyAssignees = function (params) {
  return fetch.get("tasks/my_assignees", params);
};

apiService.tasksManagerStats = function (params) {
  return fetch({
    url: "tasks/stats",
    method: "get",
    params,
  });
};

apiService.tasksAttachmentsDelete = function (attachID) {
  return fetch.delete(`tasks/attachments/${attachID}/delete`);
};

//===========Reports==========

apiService.getReports = function (module, params) {
  return fetch({
    url: `reports/${module}`,
    method: "get",
    params,
  });
};

apiService.exportReports = function (module, params) {
  return fetch({
    url: `reports/${module}/export`,
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.exportSummary = function (module, params) {
  return fetch({
    url: `reports/${module}/summary`,
    method: "get",
    responseType: "blob",
    params,
  });
};
//==========Departments============

apiService.getDepartments = function (params) {
  return fetch({
    url: "categories/list",
    method: "get",
    params,
  });
};

apiService.CreateDepartments = function (params) {
  return fetch.post("categories/create", params);
};

apiService.delDepartments = function (departID) {
  return fetch({
    url: "categories/delete/" + departID,
    method: "get",
  });
};

apiService.updateAllDepartments = function (params) {
  return fetch.put("categories/updateall", params);
};

apiService.getDepartmentsRequests = function (departID, params) {
  return fetch({
    url: "categories/requests/" + departID,
    method: "get",
    params,
  });
};

//==========Positions============

apiService.getPositions = function (params) {
  return fetch({
    url: "positions/list",
    method: "get",
    params,
  });
};

apiService.CreatePositions = function (data) {
  return fetch.post("positions/create", data);
};

apiService.UpdatePositions = function (data) {
  return fetch.put("positions/update", data);
};

apiService.ViewPositions = function (positionID) {
  return fetch({
    url: "positions/view/" + positionID,
    method: "get",
  });
};

apiService.delPositions = function (positionID) {
  return fetch.delete("positions/delete/" + positionID);
};

//==========working schedules============

apiService.getWorkingSchedules = function (params) {
  return fetch({
    url: "working_schedules/list",
    method: "get",
    params,
  });
};

apiService.getAllWorkingSchedules = function (params) {
  return fetch({
    url: "working_schedules/listall",
    method: "get",
    params,
  });
};

apiService.CreateWorkingSchedule = function (data) {
  return fetch.post("working_schedules/create", data);
};

apiService.UpdateWorkingSchedule = function (data) {
  return fetch.put("working_schedules/update", data);
};

apiService.ViewWorkingSchedule = function (positionID) {
  return fetch({
    url: "working_schedules/view/" + positionID,
    method: "get",
  });
};

apiService.delWorkingSchedule = function (positionID) {
  return fetch.delete("working_schedules/delete/" + positionID);
};

//==========Contracts============

apiService.getContracts = function (params) {
  return fetch({
    url: "contracts/list",
    method: "get",
    params,
  });
};

apiService.getAllContracts = function (params) {
  return fetch({
    url: "contracts/listall",
    method: "get",
    params,
  });
};

apiService.exportContracts = function (params) {
  return fetch({
    url: "contracts/listall",
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.CreateContract = function (params) {
  return fetch.post("contracts/create", params);
};

apiService.UpdateContract = function (params) {
  return fetch.put("contracts/update", params);
};

apiService.ViewContract = function (ContractID) {
  return fetch({
    url: "contracts/view/" + ContractID,
    method: "get",
  });
};

apiService.delContract = function (ContractID) {
  return fetch.delete("contracts/delete/" + ContractID);
};

apiService.cancelContract = function (ContractID) {
  return fetch.put("contracts/cancel/" + ContractID);
};

apiService.deleteContractAttachment = function (fileId, params) {
  return fetch.post("contracts_attachments/delete/" + fileId, params);
};

//==========Passports============

apiService.getPassports = function (params) {
  return fetch({
    url: "passports/list",
    method: "get",
    params,
  });
};

apiService.CreatePassport = function (params) {
  return fetch.post("passports/create", params);
};

apiService.UpdatePassport = function (params) {
  return fetch.put("passports/update", params);
};

apiService.ViewPassport = function (passportID) {
  return fetch({
    url: "passports/view/" + passportID,
    method: "get",
  });
};

apiService.delPassport = function (passportID) {
  return fetch.delete("passports/delete/" + passportID);
};

apiService.deletePassportAttachment = function (fileId, params) {
  return fetch.post("passports_attachments/delete/" + fileId, params);
};

//==========Driving licenses============

apiService.getDrivingLicenses = function (params) {
  return fetch({
    url: "driving_licenses/list",
    method: "get",
    params,
  });
};

apiService.CreateDrivingLicense = function (params) {
  return fetch.post("driving_licenses/create", params);
};

apiService.UpdateDrivingLicense = function (params) {
  return fetch.put("driving_licenses/update", params);
};

apiService.ViewDrivingLicense = function (drivingLicensesId) {
  return fetch({
    url: "driving_licenses/view/" + drivingLicensesId,
    method: "get",
  });
};

apiService.delDrivingLicense = function (drivingLicensesId) {
  return fetch.delete("driving_licenses/delete/" + drivingLicensesId);
};

apiService.deleteDrivingLicenseAttachment = function (fileId, params) {
  return fetch.post("driving_licenses_attachments/delete/" + fileId, params);
};

//==========Residences============

apiService.getResidences = function (params) {
  return fetch({
    url: "residences/list",
    method: "get",
    params,
  });
};

apiService.CreateResidence = function (params) {
  return fetch.post("residences/create", params);
};

apiService.UpdateResidence = function (params) {
  return fetch.put("residences/update", params);
};

apiService.ViewResidence = function (residenceId) {
  return fetch({
    url: "residences/view/" + residenceId,
    method: "get",
  });
};

apiService.delResidence = function (residenceId) {
  return fetch.delete("residences/delete/" + residenceId);
};

apiService.deleteResidenceAttachment = function (fileId, params) {
  return fetch.post("residences_attachments/delete/" + fileId, params);
};

//==========Residences============

apiService.getMedicalInsurances = function (params) {
  return fetch({
    url: "medical_insurances/list",
    method: "get",
    params,
  });
};

apiService.CreateMedicalInsurance = function (params) {
  return fetch.post("medical_insurances/create", params);
};

apiService.UpdateMedicalInsurance = function (params) {
  return fetch.put("medical_insurances/update", params);
};

apiService.ViewMedicalInsurance = function (medicalInsuranceId) {
  return fetch({
    url: "medical_insurances/view/" + medicalInsuranceId,
    method: "get",
  });
};

apiService.delMedicalInsurance = function (medicalInsuranceId) {
  return fetch.delete("medical_insurances/delete/" + medicalInsuranceId);
};

apiService.deleteMedicalInsuranceAttachment = function (fileId, params) {
  return fetch.post("medical_insurances_attachments/delete/" + fileId, params);
};

//==========Residences============

apiService.getUserBankAccounts = function (params) {
  return fetch({
    url: "user-bank-accounts/list",
    method: "get",
    params,
  });
};

apiService.CreateUserBankAccount = function (params) {
  return fetch.post("user-bank-accounts/create", params);
};

apiService.UpdateUserBankAccount = function (params, bankAccountId) {
  return fetch.put(`user-bank-accounts/${bankAccountId}/update`, params);
};

apiService.ViewUserBankAccount = function (bankAccountId) {
  return fetch({
    url: "user-bank-accounts/view/" + bankAccountId,
    method: "get",
  });
};

apiService.delUserBankAccount = function (bankAccountId) {
  return fetch.delete(`user-bank-accounts/${bankAccountId}/delete/`);
};

apiService.deleteUserBankAccountAttachment = function (fileId, params) {
  return fetch.post("user_bank_accounts_attachments/delete/" + fileId, params);
};

//==========Expenses============

apiService.getExpenses = function (params) {
  return fetch({
    url: "expenses/list",
    method: "get",
    params,
  });
};

apiService.CreateExpense = function (params) {
  return fetch.post("expenses/create", params);
};

apiService.UpdateExpense = function (params) {
  return fetch.put("expenses/update", params);
};

apiService.ViewExpense = function (expenseId) {
  return fetch({
    url: "expenses/view/" + expenseId,
    method: "get",
  });
};

apiService.delExpense = function (expenseId) {
  return fetch.delete("expenses/delete/" + expenseId);
};

apiService.deleteExpenseAttachment = function (fileId, params) {
  return fetch.post("expenses_attachments/delete/" + fileId, params);
};

//==========Social insurances============

apiService.getSocialInsurances = function (params) {
  return fetch({
    url: "social_insurances/list",
    method: "get",
    params,
  });
};

apiService.CreateSocialInsurance = function (params) {
  return fetch.post("social_insurances/create", params);
};

apiService.UpdateSocialInsurance = function (params) {
  return fetch.put("social_insurances/update", params);
};

apiService.ViewSocialInsurance = function (socialInsuranceId) {
  return fetch({
    url: "social_insurances/view/" + socialInsuranceId,
    method: "get",
  });
};

apiService.delSocialInsurance = function (socialInsuranceId) {
  return fetch.delete("social_insurances/delete/" + socialInsuranceId);
};

apiService.deleteSocialInsuranceAttachment = function (fileId, params) {
  return fetch.post("social_insurances_attachments/delete/" + fileId, params);
};

//==========Education Certificate============

apiService.getEducationCertificates = function (params) {
  return fetch({
    url: "education_certificates/list",
    method: "get",
    params,
  });
};

apiService.CreateEducationCertificate = function (params) {
  return fetch.post("education_certificates/create", params);
};

apiService.UpdateEducationCertificate = function (params) {
  return fetch.put("education_certificates/update", params);
};

apiService.ViewEducationCertificate = function (educationCertificateId) {
  return fetch({
    url: "education_certificates/view/" + educationCertificateId,
    method: "get",
  });
};

apiService.delEducationCertificate = function (educationCertificateId) {
  return fetch.delete("education_certificates/delete/" + educationCertificateId);
};

apiService.deleteEducationCertificateAttachment = function (fileId, params) {
  return fetch.post("education_certificates_attachments/delete/" + fileId, params);
};

//==========Additional Document============

apiService.getAdditionalDocuments = function (params) {
  return fetch({
    url: "additional_documents/list",
    method: "get",
    params,
  });
};

apiService.CreateAdditionalDocument = function (params) {
  return fetch.post("additional_documents/create", params);
};

apiService.UpdateAdditionalDocument = function (params) {
  return fetch.put("additional_documents/update", params);
};

apiService.ViewAdditionalDocument = function (additionalDocumentsId) {
  return fetch({
    url: "additional_documents/view/" + additionalDocumentsId,
    method: "get",
  });
};

apiService.delAdditionalDocument = function (additionalDocumentsId) {
  return fetch.delete("additional_documents/delete/" + additionalDocumentsId);
};

apiService.deleteAdditionalDocumentAttachment = function (fileId, params) {
  return fetch.post("additional_documents_attachments/delete/" + fileId, params);
};

//==========Additional Document============

apiService.getCompanyDocuments = function (params) {
  return fetch({
    url: "company_documents/list",
    method: "get",
    params,
  });
};

apiService.CreateCompanyDocument = function (params) {
  return fetch.post("company_documents/create", params);
};

apiService.UpdateCompanyDocument = function (params) {
  return fetch.put("company_documents/update", params);
};

apiService.ViewCompanyDocument = function (companyDocumentsId) {
  return fetch({
    url: "company_documents/view/" + companyDocumentsId,
    method: "get",
  });
};

apiService.delCompanyDocument = function (companyDocumentsId) {
  return fetch.delete("company_documents/delete/" + companyDocumentsId);
};

apiService.deleteCompanyDocumentAttachment = function (fileId, params) {
  return fetch.post("company_documents_attachments/delete/" + fileId, params);
};

//========== Family Members ============

apiService.getFamilyMembers = function (params) {
  return fetch({
    url: "family_members/list",
    method: "get",
    params,
  });
};

apiService.CreateFamilyMember = function (params) {
  return fetch.post("family_members/create", params);
};

apiService.UpdateFamilyMember = function (params) {
  return fetch.put("family_members/update", params);
};

apiService.ViewFamilyMember = function (familyMemberId) {
  return fetch({
    url: "family_members/view/" + familyMemberId,
    method: "get",
  });
};

apiService.delFamilyMember = function (familyMemberId) {
  return fetch.delete("family_members/delete/" + familyMemberId);
};

apiService.deleteFamilyMemberAttachment = function (fileId, params) {
  return fetch.post("family_members_attachments/delete/" + fileId, params);
};

//========== Countries ============

apiService.getCountries = function (params) {
  return fetch({
    url: "countries/list",
    method: "get",
    params,
  });
};

apiService.getAllCountries = function (params) {
  return fetch({
    url: "countries/listall",
    method: "get",
    params,
  });
};

apiService.CreateCountry = function (params) {
  return fetch.post("countries/create", params);
};

apiService.UpdateCountry = function (params) {
  return fetch.put("countries/update", params);
};

apiService.ViewCountry = function (countryId) {
  return fetch({
    url: "countries/view/" + countryId,
    method: "get",
  });
};

apiService.delCountry = function (countryId) {
  return fetch.delete("countries/delete/" + countryId);
};

//==========Request Types============

apiService.getRequestTypes = function (params) {
  return fetch({
    url: "request_types/list",
    method: "get",
    params,
  });
};

//==========Entities============

apiService.getEntities = function (params) {
  return fetch({
    url: "entities/list",
    method: "get",
    params,
  });
};

apiService.exportEntities = function (params) {
  return fetch({
    url: "entities/list",
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.CreateEntity = function (params) {
  return fetch.post("entities/create", params);
};

apiService.deleteEntity = function (entitiesID) {
  return fetch.delete("entities/delete/" + entitiesID);
};

apiService.viewEntity = function (entitiesID) {
  return fetch({
    url: "entities/view/" + entitiesID,
    method: "get",
  });
};

apiService.updateEntity = function (entityId, data) {
  return fetch.post(`entities/update/${entityId}`, data);
};

//==========Workflows============

apiService.CreateWorkflow = function (params) {
  return fetch.post("workflows/create", params);
};

apiService.CloneWorkflow = function (params) {
  return fetch.post("workflows/clone", params);
};

apiService.getWorkflows = function (params) {
  return fetch({
    url: "workflows/list",
    method: "get",
    params,
  });
};

apiService.deleteWorkflow = function (workflowID) {
  return fetch({
    url: "workflows/delete/" + workflowID,
    method: "get",
  });
};

apiService.viewWorkflow = function (workflowID) {
  return fetch({
    url: "workflows/view/" + workflowID,
    method: "get",
  });
};

apiService.updateWorkflow = function (params) {
  return fetch.post("workflows/update", params);
};

//========== Requests ============

apiService.getRequests = function (params) {
  return fetch({
    url: "requests/list",
    method: "get",
    params,
  });
};

apiService.exportRequests = function (params) {
  return fetch({
    url: "requests/list",
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.getRequestsHistory = function (params) {
  return fetch({
    url: "requests/history/list",
    method: "get",
    params,
  });
};

apiService.exportRequestsHistory = function (params) {
  return fetch({
    url: "requests/history/list",
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.getRequestsWaitingClearance = function (params) {
  return fetch({
    url: "requests/waiting_clearance/list",
    method: "get",
    params,
  });
};

apiService.exportRequestsWaitingClearance = function (params) {
  return fetch({
    url: "requests/waiting_clearance/list",
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.getRequestsRejected = function (params) {
  return fetch({
    url: "requests/rejected/list",
    method: "get",
    params,
  });
};

apiService.exportRequestsRejected = function (params) {
  return fetch({
    url: "requests/rejected/list",
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.getRequest = function (id) {
  return fetch({
    url: `requests/view/${id}`,
    method: "get",
  });
};

apiService.UpdateRequest = function (data) {
  return fetch.put("requests/update", data);
};

//========== Requests actions ============

apiService.createRequestAction = function (requestId, data) {
  return fetch.post("requests/actions/" + requestId, data);
};

apiService.rejectRequestAction = function (requestId, data) {
  return fetch.post(`requests/actions/${requestId}/reject`, data);
};

//========== Home Dashboard ============

apiService.getOverviewStatistics = function (params) {
  return fetch({
    url: "statistics/overview",
    method: "get",
    params,
  });
};

apiService.getHomeRequestsStatistics = function (params) {
  return fetch({
    url: "home/requests_statistics",
    method: "get",
    params,
  });
};

apiService.getHomeUserstatistics = function (params) {
  return fetch({
    url: "home/users_statistics",
    method: "get",
    params,
  });
};

//========== Vacation Request ============

apiService.UpdateVacationRequest = function (params) {
  return fetch.put("vacation_request/update", params);
};

//========== Exit Request ============

apiService.UpdateExitRequest = function (params) {
  return fetch.put("exit_requests/update", params);
};

//========== Complaint Request ============

apiService.UpdateExitRequest = function (params) {
  return fetch.put("complaint_requests/update", params);
};

//========== Expiring Documents ============

apiService.getExpiringDocuments = function (params) {
  return fetch({
    url: "expired_documents/list",
    method: "get",
    params,
  });
};

apiService.exportExpiringDocuments = function (params) {
  return fetch({
    url: "expired_documents/list",
    method: "get",
    responseType: "blob",
    params,
  });
};
//========== Vacation Request Info ============

apiService.UpdateVacationRequestTicketsInfo = function (params) {
  return fetch.put("vacation_request_tickets_info/update", params);
};

apiService.delVacationRequestTicketsInfo = function (id) {
  return fetch.delete("vacation_request_tickets_info/delete/" + id);
};

//==========Clearance Templates============

apiService.CreateClearanceTemplate = function (data) {
  return fetch.post("clearance_templates/create", data);
};

apiService.getClearanceTemplates = function (params) {
  return fetch({
    url: "clearance_templates/list",
    method: "get",
    params,
  });
};

apiService.delClearanceTemplate = function (id) {
  return fetch.delete("clearance_templates/delete/" + id);
};

apiService.ViewClearanceTemplate = function (clearanceTemplateID) {
  return fetch({
    url: "clearance_templates/view/" + clearanceTemplateID,
    method: "get",
  });
};

apiService.UpdateClearanceTemplate = function (data) {
  return fetch.put("clearance_templates/update", data);
};

//==========Clearance Requests============

apiService.getClearanceRequests = function (params) {
  return fetch({
    url: "clearance_requests/list",
    method: "get",
    params,
  });
};

apiService.exportClearanceRequests = function (params) {
  return fetch({
    url: "clearance_requests/list",
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.ViewClearanceRequest = function (clearanceRequestId) {
  return fetch({
    url: "clearance_requests/view/" + clearanceRequestId,
    method: "get",
  });
};

apiService.UpdateClearanceRequest = function (params) {
  return fetch.put("clearance_requests/update", params);
};
//==========Send Notifications============

apiService.SendNotifications = function (data) {
  return fetch.post("send_notifications/send", data);
};

//========== Transfer Request ============

//this is all requests with type transfer
apiService.getAllTransferRequest = function () {
  return fetch.get("transfer_requests/listall");
};

//========== Transfers ============

//this is all requests with type transfer
apiService.createTransfer = function (data) {
  return fetch.post("transfers/create", data);
};

//==========Assets============

apiService.getAssets = function (params) {
  return fetch({
    url: "assets/list",
    method: "get",
    params,
  });
};

apiService.exportAssets = function (params) {
  return fetch({
    url: "assets/list",
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.CreateAsset = function (params) {
  return fetch.post("assets/create", params);
};

apiService.deleteAsset = function (assetID) {
  return fetch.delete("assets/delete/" + assetID);
};

apiService.viewAsset = function (assetID) {
  return fetch({
    url: "assets/view/" + assetID,
    method: "get",
  });
};

apiService.updateAsset = function ( data) {
  return fetch.post(`assets/update`, data);
};

apiService.assignAsset = function ( data) {
  return fetch.post(`assets/assign`, data);
};


//==========Inventory============

apiService.getInventory = function (params) {
  return fetch({
    url: "inventory/list",
    method: "get",
    params,
  });
};

apiService.exportInventory = function (params) {
  return fetch({
    url: "inventory/list",
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.CreateInventory = function (params) {
  return fetch.post("inventory/create", params);
};

apiService.deleteInventory = function (inventoryID) {
  return fetch.delete("inventory/delete/" + inventoryID);
};

apiService.viewInventory = function (inventoryID) {
  return fetch({
    url: "inventory/view/" + inventoryID,
    method: "get",
  });
};

apiService.updateInventory = function ( data) {
  return fetch.post(`inventory/update`, data);
};

apiService.addToInventoryStock = function ( data) {
  return fetch.post(`inventory_stocks/add`, data);
};

apiService.withdrawFromInventoryStock = function ( data) {
  return fetch.post(`inventory_stocks/withdraw`, data);
};


//==========Inventory============

apiService.getEquipments = function (params) {
  return fetch({
    url: "equipments/list",
    method: "get",
    params,
  });
};

apiService.exportEquipments = function (params) {
  return fetch({
    url: "equipments/list",
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.CreateEquipment = function (params) {
  return fetch.post("equipments/create", params);
};

apiService.deleteEquipment = function (equipmentID) {
  return fetch.delete("equipments/delete/" + equipmentID);
};

apiService.viewEquipment = function (equipmentID) {
  return fetch({
    url: "equipments/view/" + equipmentID,
    method: "get",
  });
};

apiService.updateEquipment = function ( data) {
  return fetch.put(`equipments/update`, data);
};

apiService.assignEquipment = function ( data) {
  return fetch.post(`equipments/assign`, data);
};

//==========Housings============

apiService.getHousings = function (params) {
  return fetch({
    url: "housings/list",
    method: "get",
    params,
  });
};

apiService.exportHousings = function (params) {
  return fetch({
    url: "housings/list",
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.CreateHousing = function (params) {
  return fetch.post("housings/create", params);
};

apiService.deleteHousing = function (housingID) {
  return fetch.delete("housings/delete/" + housingID);
};

apiService.viewHousing = function (housingID) {
  return fetch({
    url: "housings/view/" + housingID,
    method: "get",
  });
};

apiService.updateHousing = function ( data) {
  return fetch.post(`housings/update`, data);
};

apiService.deleteHousingAttachment = function (fileId, params) {
  return fetch.post("housings_attachments/delete/" + fileId, params);
};

//==========Projects============

apiService.getSkyEyeProjects = function (params) {
  return fetch({
    url: "projects/skyeye",
    method: "get",
    params,
  });
};

apiService.getProjectsStats = function (params) {
  return fetch({
    url: "projects/stats",
    method: "get",
    params,
  });
};

apiService.getProjects = function (params) {
  return fetch({
    url: "projects/list",
    method: "get",
    params,
  });
};

apiService.exportProjects = function (params) {
  return fetch({
    url: "projects/list",
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.CreateProject = function (params) {
  return fetch.post("projects/create", params);
};

apiService.deleteProject = function (id) {
  return fetch.delete("projects/delete/" + id);
};

apiService.viewProject = function (id) {
  return fetch({
    url: "projects/view/" + id,
    method: "get",
  });
};

apiService.updateProject = function ( data) {
  return fetch.post(`projects/update`, data);
};

apiService.getProjectCompanies = function (params) {
  return fetch({
    url: "projects/companies",
    method: "get",
    params,
  });
};

//==========Camps============

apiService.getCamps = function (params) {
  return fetch({
    url: "camps/list",
    method: "get",
    params,
  });
};

apiService.exportCamps = function (params) {
  return fetch({
    url: "camps/list",
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.CreateCamp = function (params) {
  return fetch.post("camps/create", params);
};

apiService.deleteCamp = function (housingID) {
  return fetch.delete("camps/delete/" + housingID);
};

apiService.viewCamp = function (housingID) {
  return fetch({
    url: "camps/view/" + housingID,
    method: "get",
  });
};

apiService.updateCamp = function ( data) {
  return fetch.post(`camps/update`, data);
};

//==========Branches============

apiService.getBranches = function (params) {
  return fetch({
    url: "branches/list",
    method: "get",
    params,
  });
};

apiService.exportBranches = function (params) {
  return fetch({
    url: "branches/list",
    method: "get",
    responseType: "blob",
    params,
  });
};

apiService.CreateBranch = function (params) {
  return fetch.post("branches/create", params);
};

apiService.deleteBranch = function (housingID) {
  return fetch.delete("branches/delete/" + housingID);
};

apiService.viewBranch = function (housingID) {
  return fetch({
    url: "branches/view/" + housingID,
    method: "get",
  });
};

apiService.updateBranch = function ( data) {
  return fetch.post(`branches/update`, data);
};

//==========Business Lines============

apiService.getBusinessLines = function (params) {
  return fetch({
    url: "business_lines/list",
    method: "get",
    params,
  });
};

apiService.CreateBusinessLine = function (params) {
  return fetch.post("business_lines/create", params);
};

apiService.deleteBusinessLine = function (housingID) {
  return fetch.delete("business_lines/delete/" + housingID);
};

apiService.viewBusinessLine = function (housingID) {
  return fetch({
    url: "business_lines/view/" + housingID,
    method: "get",
  });
};

apiService.updateBusinessLine = function ( data) {
  return fetch.post(`business_lines/update`, data);
};

//==========Projects RPOs============

apiService.getRPOStatusList = function (params) {
  return fetch({
    url: "projects_rpos/status_list",
    method: "get",
    params,
  });
};

apiService.getProjectsRPOs = function (params, project_id) {
  return fetch({
    url: "projects_rpos/list/" + project_id,
    method: "get",
    params,
  });
};

apiService.CreateProjectsRPO = function (params) {
  return fetch.post("projects_rpos/create", params);
};

apiService.deleteProjectsRPO = function (recordid) {
  return fetch.delete("projects_rpos/delete/" + recordid);
};

apiService.viewProjectsRPO = function (recordid) {
  return fetch({
    url: "projects_rpos/view/" + recordid,
    method: "get",
  });
};

apiService.updateProjectsRPO = function ( data) {
  return fetch.post(`projects_rpos/update`, data);
};

//==========Project Zones============

apiService.getProjectZones = function (params) {
  return fetch({
    url: "projects_zones/list",
    method: "get",
    params,
  });
};

//========== Shifts ============

apiService.getShifts = function (params) {
  return fetch({
    url: "shifts/list",
    method: "get",
    params,
  });
};

apiService.createShift = function (data) {
  return fetch.post("shifts/create", data);
};

apiService.deleteShift = function (shiftID) {
  return fetch.delete("shifts/delete/" + shiftID);
};

apiService.viewShift = function (shiftID) {
  return fetch({
    url: "shifts/view/" + shiftID,
    method: "get",
  });
};

apiService.updateShift = function ( data) {
  return fetch.put(`shifts/update`, data);
};

//========== Shifts Scheduler ============

apiService.getShiftsScheduler = function (params) {
  return fetch({
    url: "shift_scheduler/list",
    method: "get",
    params,
  });

};

apiService.viewShiftScheduler = function (shiftID) {
  return fetch({
    url: "shift_scheduler/view/" + shiftID,
    method: "get",
  });
};

apiService.updateShiftScheduler = function ( data) {
  return fetch.put(`shift_scheduler/update`, data);
};

//========== Attendance ============

apiService.getAttendance = function (params) {
  return fetch({
    url: "attendances/list",
    method: "get",
    params,
  });
};

apiService.viewAttendance = function (attendanceID) {
  return fetch({
    url: "attendances/view/" + attendanceID,
    method: "get",
  });
};

apiService.updateAttendance = function (data) {
  return fetch.put(`attendances/update`, data);
};

//==========Positions============

apiService.getQuantityUnits = function (params) {
  return fetch({
    url: "quantity_units/list",
    method: "get",
    params,
  });
};

apiService.CreateQuantityUnit = function (data) {
  return fetch.post("quantity_units/create", data);
};

apiService.UpdateQuantityUnit = function (data) {
  return fetch.put("quantity_units/update", data);
};

apiService.ViewQuantityUnit = function (positionID) {
  return fetch({
    url: "quantity_units/view/" + positionID,
    method: "get",
  });
};

apiService.delQuantityUnit = function (positionID) {
  return fetch.delete("quantity_units/delete/" + positionID);
};

//========== Asset Types ============

apiService.getAssetTypes = function (params) {
  return fetch({
    url: "asset_types/list",
    method: "get",
    params,
  });
};

apiService.CreateAssetType = function (data) {
  return fetch.post("asset_types/create", data);
};

apiService.UpdateAssetType = function (data) {
  return fetch.put("asset_types/update", data);
};

apiService.ViewAssetType = function (positionID) {
  return fetch({
    url: "asset_types/view/" + positionID,
    method: "get",
  });
};

apiService.delAssetType = function (positionID) {
  return fetch.delete("asset_types/delete/" + positionID);
};

//========== Inventory Types ============

apiService.getInventoryTypes = function (params) {
  return fetch({
    url: "inventory_types/list",
    method: "get",
    params,
  });
};

apiService.CreateInventoryType = function (data) {
  return fetch.post("inventory_types/create", data);
};

apiService.UpdateInventoryType = function (data) {
  return fetch.put("inventory_types/update", data);
};

apiService.ViewInventoryType = function (positionID) {
  return fetch({
    url: "inventory_types/view/" + positionID,
    method: "get",
  });
};

apiService.delInventoryType = function (positionID) {
  return fetch.delete("inventory_types/delete/" + positionID);
};

//==========Positions============

apiService.getPayroll = function (params) {
  return fetch({
    url: "payroll/list",
    method: "get",
    params,
  });
};

apiService.exportPayroll = function (params) {
  return fetch({
    url: "payroll/list",
    method: "get",
    responseType: "blob",
    params,
  });
};

export default apiService;
