import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login-2')),
    },
    {
        key: 'first-time-login',
        path: `${AUTH_PREFIX_PATH}/first-time-login`,
        component: React.lazy(() => import('views/auth-views/authentication/first-time-login')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: '404',
        path: `${AUTH_PREFIX_PATH}/404`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
    },
    {
        key: '404',
        path: `${APP_PREFIX_PATH}/404`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
    }
]

const routeRestGroup = (component) => {
    return [
        { folder: component, path: null, component: "list" },
        { folder: component, path: null, component: "add" },
        { folder: component, path: ":id/edit", component: "edit" },
        { folder: component, path: "edit/:id", component: "edit" },
        { folder: component, path: "view/:id", component: "view" },
    ]
}

let protectedLinks = []

protectedLinks = [...protectedLinks, ...[
    { folder: "dashboard", path: null, component: "hr" },
    { folder: "dashboard", path: null, component: "tasks" },
    { folder: "dashboard", path: null, component: "overview" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "taskman", path: null, component: "list" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "attendance", path: null, component: "list" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "contracts", path: null, component: "list" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "authcontrol", path: "*", component: "form/index" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "chat", path: "*", component: "index" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "departments", path: null, component: "list" },
    { folder: "departments", path: ":id/requests", component: "requests" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "shifts", path: null, component: "list" },
    { folder: "shifts", path: "edit/:id", component: "edit" },
    { folder: "shifts", path: "add", component: "edit" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "projects-rpos", path: "list/:project_id", component: "list" },
    { folder: "projects-rpos", path: "edit/:id/:project_id", component: "edit" },
    { folder: "projects-rpos", path: "add/:project_id", component: "add" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "staff", path: null, component: "list" },
    { folder: "staff", path: "add", component: "StaffForm" },
    { folder: "staff", path: "edit/:id/information/contracts/add", component: "contracts/add" },
    { folder: "staff", path: "edit/:id/information/user-bank-account/add", component: "user-bank-account/add" },
    { folder: "staff", path: "edit/:id/information/education-certificates/add", component: "education-certificates/add" },
    { folder: "staff", path: "edit/:id/information/additional-documents/add", component: "additional-documents/add" },
    { folder: "staff", path: "edit/:id/information/expenses/add", component: "expenses/add" },
    { folder: "staff", path: "edit/:id/information/passports/add", component: "passports/add" },
    { folder: "staff", path: "edit/:id/information/driving-licenses/add", component: "driving-licenses/add" },
    { folder: "staff", path: "edit/:id/information/residences/add", component: "residences/add" },
    { folder: "staff", path: "edit/:id/information/social-insurances/add", component: "social-insurances/add" },
    { folder: "staff", path: "edit/:id/information/medical-insurances/add", component: "medical-insurances/add" },
    { folder: "staff", path: "edit/:id/information/family-members/add", component: "family-members/add" },
    { folder: "staff", path: "edit/:id/information/contracts/:contractid/edit", component: "contracts/edit" },
    { folder: "staff", path: "edit/:id/information/user-bank-account/:bankAccountId/edit", component: "user-bank-account/edit" },
    { folder: "staff", path: "edit/:id/information/education-certificates/:educationcertificateid/edit", component: "education-certificates/edit" },
    { folder: "staff", path: "edit/:id/information/additional-documents/:additionaldocumentid/edit", component: "education-certificates/edit" },
    { folder: "staff", path: "edit/:id/information/expenses/:expenseId/edit", component: "expenses/edit" },
    { folder: "staff", path: "edit/:id/information/passports/:passportid/edit", component: "passports/edit" },
    { folder: "staff", path: "edit/:id/information/driving-licenses/:drivinglicensesid/edit", component: "driving-licenses/edit" },
    { folder: "staff", path: "edit/:id/information/residences/:residenceid/edit", component: "residences/edit" },
    { folder: "staff", path: "edit/:id/information/social-insurances/:socialinsuranceid/edit", component: "social-insurances/edit" },
    { folder: "staff", path: "edit/:id/information/medical-insurances/:medicalinsuranceid/edit", component: "medical-insurances/edit" },
    { folder: "staff", path: "edit/:id/information/family-members/:familymemberid/edit", component: "family-members/edit" },
    { folder: "staff", path: "edit/:id/information/contracts", component: "contracts/list" },
    { folder: "staff", path: "edit/:id/information/user-bank-account", component: "user-bank-account/list" },
    { folder: "staff", path: "edit/:id/information/education-certificates", component: "education-certificates/list" },
    { folder: "staff", path: "edit/:id/information/additional-documents", component: "additional-documents/list" },
    { folder: "staff", path: "edit/:id/information/expenses", component: "expenses/list" },
    { folder: "staff", path: "edit/:id/information/passports", component: "passports/list" },
    { folder: "staff", path: "edit/:id/information/driving-licenses", component: "driving-licenses/list" },
    { folder: "staff", path: "edit/:id/information/residences", component: "residences/list" },
    { folder: "staff", path: "edit/:id/information/social-insurances", component: "social-insurances/list" },
    { folder: "staff", path: "edit/:id/information/medical-insurances", component: "medical-insurances/list" },
    { folder: "staff", path: "edit/:id/information/family-members", component: "family-members/list" },
    { folder: "staff", path: "edit/:id", component: "StaffForm" },
    { folder: "staff", path: "profile/:id", component: "profile" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "expired-documents", path: null, component: "list" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "payroll", path: null, component: "list" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "request-types", path: null, component: "list" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "shift-scheduler", path: null, component: "list" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "falmap", path: "*", component: "index" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "interactive", path: "*", component: "index" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "send-notifications", path: "*", component: "add" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "settings", path: "*", component: "index" },
]]

protectedLinks = [...protectedLinks, ...[
    { folder: "skyeye", path: "*", component: "index" },
]]

protectedLinks = [...protectedLinks, ...routeRestGroup("branches")]

protectedLinks = [...protectedLinks, ...routeRestGroup("assets")]

protectedLinks = [...protectedLinks, ...routeRestGroup("asset-types")]
protectedLinks = [...protectedLinks, ...routeRestGroup("camps")]
protectedLinks = [...protectedLinks, ...routeRestGroup("clearance-requests")]
protectedLinks = [...protectedLinks, ...routeRestGroup("clearance-templates")]
protectedLinks = [...protectedLinks, ...routeRestGroup("company-documents")]
protectedLinks = [...protectedLinks, ...routeRestGroup("countries")]
protectedLinks = [...protectedLinks, ...routeRestGroup("entities")]
protectedLinks = [...protectedLinks, ...routeRestGroup("equipments")]
protectedLinks = [...protectedLinks, ...routeRestGroup("housings")]
protectedLinks = [...protectedLinks, ...routeRestGroup("inventory")]
protectedLinks = [...protectedLinks, ...routeRestGroup("inventory-types")]
protectedLinks = [...protectedLinks, ...routeRestGroup("managers")]
protectedLinks = [...protectedLinks, ...routeRestGroup("positions")]
protectedLinks = [...protectedLinks, ...routeRestGroup("projects")]
protectedLinks = [...protectedLinks, ...routeRestGroup("quantity-units")]
protectedLinks = [...protectedLinks, ...routeRestGroup("requests")]
protectedLinks = [...protectedLinks, ...routeRestGroup("vehicles")]
protectedLinks = [...protectedLinks, ...routeRestGroup("workflows")]
protectedLinks = [...protectedLinks, ...routeRestGroup("working-schedules")]

export const protectedRoutes = protectedLinks.map((link, index) => { return {
    key: index,
    path: `${APP_PREFIX_PATH}/${link.folder}/${link.path ? link.path : link.component}`,
    component: React.lazy(() => import((`views/app-views/${link.folder}/${link.component}`))),
} } )
