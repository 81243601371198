const dev = {
  API_ENDPOINT_URL: "https://fal-hr-api.detailslocal.com/api",
  SITE_URL: "https://fal-hr-api.detailslocal.com",
  WS_SOCKET_URL: "wss://detailslocal.com:888",
  WS_SOCKET_PORT: 888,
};

const prod = {
  API_ENDPOINT_URL: "https://hr-api.arabianfal.net/api",
  SITE_URL: "https://hr-api.arabianfal.net",
  WS_SOCKET_URL: "wss://arabianfal.net:5000",
  WS_SOCKET_PORT: 5000,
};

const test = {
  API_ENDPOINT_URL: "https://hr-api.arabianfal.net/api",
  SITE_URL: "https://hr-api.arabianfal.net",
  WS_SOCKET_URL: "wss://arabianfal.net:5000",
  WS_SOCKET_PORT: 5000,
};

const getEnv = () => {
  if (window.location.href.search("staging-fal-hr.") >= 0) {
    return test;
  }

  if (window.location.href.search("falsys.") >= 0) {
    return dev;
  }

  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
